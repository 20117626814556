import React from 'react'
import {graphql, useStaticQuery, Link} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import PageHeader from '../../components/Layout/PageHeader'
import PageImageCarousel from '../../components/PageImageCarousel/page-image-carousel'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import {Inner, InnerSkinny} from '../../components/styles'
import TestimonialsSlider from '../../components/TestimonialSlider'

import FeaturedInLogoWall from '../../components/shared/FeaturedInLogoWall'

const ReviewsPage = ({location}) => {
  const data = useStaticQuery(graphql`
    {
      wp: wordpressPage(wordpress_id: {eq: 129}) {
        title
        id
        acf {
          heading
          sub_heading_content
          second_heading
          second_heading_content
          bottom_strip_heading
          bottom_heading_content
          customers_keep_their_kooi {
            localFile {
              childImageSharp {
                fluid(quality: 95, srcSetBreakpoints: [100]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          page_slider {
            slider_image {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxHeight: 700, srcSetBreakpoints: [100]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            slider_image_mobile {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 400, srcSetBreakpoints: [100]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
      seo: wpgraphql {
        pageBy(pageId: 129) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `)

  return (
    <Layout location={location} flush>
      <SEO location={location} seo={data.seo.pageBy.seo} title="Reviews" />
      <PageHeader location={location}>{data.wp.title}</PageHeader>
      {data.wp.acf.page_slider && (
        <PageImageCarousel images={data.wp.acf.page_slider} addNegativeMargin />
      )}
      <FirstBlock>
        <Inner>
          <InnerSkinny>
            <h2 className="red">{data.wp.acf.heading}</h2>
            <p>{data.wp.acf.sub_heading_content}</p>
          </InnerSkinny>
          <ShopButton />
        </Inner>
      </FirstBlock>
      <FeaturedInLogoWall />
      <SecondBlock>
        <Img
          fluid={
            data.wp.acf.customers_keep_their_kooi.localFile.childImageSharp
              .fluid
          }
          alt=""
        />
        <div>
          <h2 className="red left">{data.wp.acf.second_heading}</h2>
          <p>{data.wp.acf.second_heading_content}</p>
        </div>
      </SecondBlock>
      <TestimonialBlock>
        <Inner>
          <TestimonialsSlider />
        </Inner>
      </TestimonialBlock>
      <LastBlock>
        <Inner>
          <h2 className="red">{data.wp.acf.bottom_strip_heading}</h2>
          <p>{data.wp.acf.bottom_heading_content} </p>
          <ShopButton />
        </Inner>
      </LastBlock>
    </Layout>
  )
}

export default ReviewsPage

const TestimonialBlock = styled.div`
  background: ${({theme}) => theme.grey2};
  color: white;
  padding: 80px 0;
`

const FirstBlock = styled.div`
  text-align: center;
  margin-bottom: 60px;
  h2 {
    text-transform: unset;
  }
  p {
    padding: 40px 0;
  }
  & div.logos {
    padding-top: 20px;
    padding-bottom: 60px;
  }
`

const SecondBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'image text';
  @media (max-width: 1600px) {
    & div:first-child {
      background-position: right !important;
    }
  }
  @media (max-width: 600px) {
    & div:nth-child(2) {
      padding: 20px 0 !important;
      width: 80%;
    }
    & div:first-child {
      margin: 20px 0 !important;
    }
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'text'
      'image';
    justify-items: center;
    text-align: center;
    h2 {
      text-align: center !important;
    }
    & div:first-child {
      margin: 60px 0 0 0 !important;
      width: 100%;
      height: auto;
    }
    & div:nth-child(2) {
      width: 90%;
      padding: 60px 0 0 0 !important;
    }
  }
  p {
    padding: 40px 0 0 0;
  }
  & div:first-child {
    grid-area: image;
  }
  & div:nth-child(2) {
    padding-top: 60px;
    width: 80%;
    justify-self: center;
    padding-bottom: 100px;
    grid-area: text;
    h2 {
      margin-bottom: 40px;
      text-align: left;
    }
  }
`

const LastBlock = styled.div`
  text-align: center;
  padding: 60px 0;
  p {
    padding: 40px 0;
  }
`

const StyledLink = styled(Link)`
  font-size: 18px;
  background: #425266;
  color: #fff;
  padding: 15px 35px;
  border-radius: 50px;
  text-transform: uppercase;
  display: inline-block;
  transition: all 200ms ease-in-out;
  &:hover {
    background: #ff043a;
    color: #fff;
  }
`

const ShopButton = () => {
  return <StyledLink to="/store/">Shop Now</StyledLink>
}
