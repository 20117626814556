import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {Inner} from '../../styles'

const FeaturedInWrapper = styled.div`
  background-color: ${props => props.theme.grey5};
  padding: 40px 20px 50px 20px;
  .logos {
    text-align: center;
    .gatsby-image-wrapper {
      display: inline-block;
      width: 10%;
      margin: 2%;
    }
  }
  @media (max-width: 900px) {
    padding: 10px 0;
    .logos {
      .gatsby-image-wrapper {
        width: 20%;
        margin: 4%;
      }
    }
  }
  @media (max-width: 500px) {
    .logos {
      .gatsby-image-wrapper {
        width: 30%;
        margin: 8%;
      }
    }
  }
`

const FeaturedInLogoWall = () => {
  const data = useStaticQuery(graphql`
    {
      wp: wordpressPage(wordpress_id: {eq: 121}) {
        acf {
          featured_in_heading
          featured_in_logos {
            source_url
            localFile {
              childImageSharp {
                fluid(maxWidth: 200, quality: 80, srcSetBreakpoints: [100]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <FeaturedInWrapper>
      <Inner>
        <h2
          dangerouslySetInnerHTML={{__html: data.wp.acf.featured_in_heading}}
          className="red"
        />
        <div className="logos">
          {data.wp.acf.featured_in_logos.map(logo => (
            <Img
              key={logo.source_url}
              fluid={logo.localFile.childImageSharp.fluid}
              alt=""
            />
          ))}
        </div>
      </Inner>
    </FeaturedInWrapper>
  )
}

export default FeaturedInLogoWall
